<template>
  <div class="flayers">
    <div class="flayers-tool">
        <el-button @click="show_create_flyer=true" type="success">פלאייר חדש</el-button>
    </div>
    <div class="flayers-tool-content">
        <table>
            <tr>
                <th>שם הפלאייר</th>
                <th>תאריך התחלה</th>
                <th>תאריך סיום</th>
                <th>לקוח מרכז</th>
                <th>פעולות</th>
            </tr>
            <h1 v-if="flayers.length==0" style="color:#fff; text-align:center;">לא קיימות רשומות להצגה</h1>
            <template v-for="flayer in flayers" :key="flayer.uid">
                <tr>
                    <td>{{flayer.flayer_name}}</td>
                    <td>{{new Date(flayer.start_date).toLocaleDateString('he')}}</td>
                    <td>{{new Date(flayer.end_date).toLocaleDateString('he')}}</td>
                    <td>{{flayer.asortment.join(',')}}</td>
                    <td>
                        <i @click="handle_open_flayer(flayer.flayer_images)" class="material-icons pdf-icon icon">image</i>
                        <i @click="handle_edit(flayer)" class="material-icons edit-icon icon">edit</i>
                        <i @click="handle_delete(flayer)" class="material-icons delete-icon icon">delete</i>
                    </td>
                </tr>
            </template>
        </table>
    </div>
    <CreateNewFlyer v-if="show_create_flyer" 
        @close="show_create_flyer=false; selected_flayer_for_edit=null;" 
        @finish="handle_finish_create_flayer" 
        :edit="selected_flayer_for_edit"
        @finish_edit="handle_finish_edit"
    />

    <DisplayFlayer v-if="show_display_flayer" @close="show_display_flayer=false; 
    selected_flayer_images=[];" :flayer_images="selected_flayer_images"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'
import {get_all_flayers_from_DB,delete_flayer_from_DB} from './Flayers/Scripts/script'
import {alert_confirm} from '../../../Methods/Msgs'
import upload_image_storage from '../../../Methods/use_general_storage'


export default {
    components:{
        CreateNewFlyer:defineAsyncComponent(()=>import('./Flayers/CreateNewFlyer.vue')),
        DisplayFlayer:defineAsyncComponent(()=>import('../../Catalog/DisplayFlayer.vue'))
    },
    setup(){
        const selected_flayer_images = ref([])
        const show_display_flayer = ref(false)

        const {delete_image_by_url}  = upload_image_storage()
        const show_create_flyer = ref(false)
        const flayers = ref([])

        const selected_flayer_for_edit = ref(null) 

        const handle_finish_create_flayer = () => {
            init()
            show_create_flyer.value = false
            selected_flayer_for_edit.value = null
        }

       
        const handle_edit = (flayer) => {
            selected_flayer_for_edit.value = JSON.parse(JSON.stringify(flayer))
            show_create_flyer.value = true
        }

        const handle_finish_edit = (flayer_data) => {
            const index = flayers.value.findIndex(f=>f.uid==flayer_data.uid)
            if(index!=-1){
                flayers.value[index] = flayer_data
            }
            selected_flayer_for_edit.value = null
            show_create_flyer.value = false
        }

        const handle_delete = (flayer) => {
            alert_confirm(`האם למחוק את הפלאייר ${flayer.flayer_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                   await delete_flayer_from_DB(flayer.uid)
                   for(const url of flayer.flayer_images){
                       await delete_image_by_url(url)
                   }
                   flayers.value = flayers.value.filter(f=>f.uid != flayer.uid)
                }
            })
        }

        const handle_open_flayer = (flayer_images) => {
            selected_flayer_images.value = flayer_images
            show_display_flayer.value = true
        }

        const init = async() => {
          flayers.value = await get_all_flayers_from_DB()
        }

        init()
        return{
            handle_edit,
            handle_open_flayer,
            handle_finish_create_flayer,
            handle_delete,
            flayers,
            show_create_flyer,
            selected_flayer_for_edit,
            handle_finish_edit,
            selected_flayer_images,
            show_display_flayer,
        }
    }
}
</script>

<style scoped>
    .flayers{
        width: 100%;
        height: 100%;
    }
    .flayers-tool{
        width: 100%;
        height: 50px;
        background: var(--alert-purple);
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .flayers-tool-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-x: auto;
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .mobile-table{
          margin-bottom: 5px;
    }

    .icon{
        font-size: 25px;
        cursor: pointer;
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .pdf-icon{
        color: red;
    }
    .delete-icon{
        color: var(--danger);
    }
</style>